<template>
  <v-container>
    <v-form ref="phone-number-form">
      <v-layout class="sheet" row wrap>
        <v-flex xs12>
          <v-layout row class="mb-3">
            <v-flex shrink>
              <h1 class="page-header">
                {{ modeTitle }} {{ brand.brandName || 'Brand' }} Phone Number
              </h1>
            </v-flex>
            <v-spacer />
            <v-flex shrink>
              <v-btn
                :id="`phone-number-detail-cancel-button`"
                color="primary"
                outline
                class="mt-0"
                @click="cancelAction"
              >
                {{ isModeView ? 'All Phone Numbers' : 'Cancel' }}
              </v-btn>
              <v-btn
                v-if="isModeView"
                :id="`phone-number-detail-edit-button`"
                color="primary"
                class="mt-0"
                @click="enableEdit"
              >
                Edit
              </v-btn>
              <v-btn
                v-if="!isModeView"
                :id="`phone-number-detail-save-button`"
                color="primary"
                class="mt-0"
                @click="savePhoneNumber"
              >
                Save
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-brand-selector`"
                :value="brand"
                label="Brand"
                placeholder="Enter Brand Name"
                type="autocomplete"
                :items="suggestedBrands"
                item-text="brandName"
                item-key="brandId"
                :return-object="true"
                :search-input.sync="brandSearchTerm"
                :clearable="true"
                clear-icon="close"
                browser-autocomplete="off"
                :disabled="disabled"
                :rules="rules.brand"
                @click:clear="resetBrand"
                @input="selectBrandFromList"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-lead-source-selector`"
                v-model="leadSource"
                label="Lead Source"
                placeholder="Enter lead source name"
                type="autocomplete"
                :items="suggestedLeadSources"
                item-text="label"
                item-key="id"
                :return-object="true"
                :search-input.sync="leadSourceSearchTerm"
                :clearable="true"
                clear-icon="close"
                browser-autocomplete="off"
                :disabled="disabled"
                :rules="rules.leadSource"
                @input="selectLeadSourceFromList"
                @click:clear="resetLeadSource"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-phone-number-input`"
                v-model="phone.phoneNumber"
                v-mask="[masks.phoneNumber]"
                label="Phone Number"
                :maxlength="masks.phoneNumber.length"
                placeholder="Enter phone number"
                :disabled="disabled"
                :rules="rules.phoneNumber"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-caller-id-input`"
                v-model="phone.callerId"
                label="Caller ID"
                placeholder="Enter caller ID name"
                :disabled="disabled"
                :rules="rules.callerId"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-answered-by-input`"
                v-model="phone.answeredBy"
                label="Answered By"
                placeholder="Enter answered by"
                :disabled="disabled"
                :rules="rules.answeredBy"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-custom-tag-input`"
                v-model="phone.customTag"
                label="Custom Tag"
                placeholder="Enter custom tag"
                :disabled="disabled"
              />
            </v-flex>
            <v-flex xs12 sm6 md3 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-url-input`"
                v-model="phone.url"
                label="URL"
                placeholder="Enter URL"
                :disabled="disabled"
                :rules="rules.url"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 md6 pr-2 pl-2>
              <CRInput
                :id="`${mode}-phone-number-detail-notes-input`"
                v-model="phone.notes"
                multi-line
                label="Notes"
                placeholder="Enter notes"
                :disabled="disabled"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
import leadSources from '@/services/leadSources'
import phoneNumbers from '@/services/phoneNumbers'
import { filter } from '@/utils/filter'
import * as logger from '@/utils/logger'
import { mask } from 'vue-the-mask'
import brands from '@/services/brands'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { deepClone } from '@/utils/deepClone'

export default {
  directives: {
    mask,
  },
  props: {
    mode: {
      type: String,
      default: 'view',
    },
  },
  data() {
    return {
      phone: {
        answeredBy: null,
        brandId: null,
        callerId: null,
        customTag: null,
        friendlyNumber: null,
        leadSourceTypeId: null,
        notes: null,
        phoneNumber: null,
        phoneNumberId: null,
        url: null,
      },
      rules: {
        brand: [(v) => v.brandId !== null || 'Brand is required'],
        leadSource: [],
        phoneNumber: [
          isRequired(true, isNotEmpty, {
            req: 'Phone number is required',
            error: 'Phone number is required',
          }),
          (v) =>
            this.getRawNumber(v).length === 11 ||
            'Must provide a standard phone number',
          (v) =>
            this.isUniquePhoneNumber ||
            this.getRawNumber(v) === this.originalPhoneNumber ||
            'This number already exists',
        ],
        callerId: [
          isRequired(true, isNotEmpty, {
            req: 'Caller ID is required',
            error: 'Caller ID is required',
          }),
        ],
        answeredBy: [
          isRequired(true, isNotEmpty, {
            req: 'Answered by is required',
            error: 'Answered by is required',
          }),
        ],
        url: [],
      },
      masks: {
        phoneNumber: '+1 (###) ###-####',
      },
      brand: {
        brandName: null,
        brandId: null,
      },
      leadSource: {
        active: null,
        companyId: null,
        description: null,
        id: null,
        key: null,
        label: null,
      },
      suggestedBrands: [],
      brandSearchTerm: undefined,
      suggestedLeadSources: [],
      leadSourceSearchTerm: undefined,
      isUniquePhoneNumber: true,
      originalPhoneNumber: null,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    modeTitle() {
      return this.mode.charAt(0).toUpperCase() + this.mode.slice(1)
    },
    disabled() {
      return this.isModeView
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  watch: {
    async brandSearchTerm(value) {
      await this.searchBrandsList(value)
    },
    async leadSourceSearchTerm(value) {
      await this.searchLeadSourceList(value)
    },
    async 'phone.phoneNumber'(value) {
      if (value.length >= this.masks.phoneNumber.length) {
        const rawNumber = this.getRawNumber(value)
        const doesPhoneNumberAlreadyExistResponse = await phoneNumbers.checkIfPhoneNumberExists(
          `+${rawNumber}`
        )
        this.isUniquePhoneNumber = !doesPhoneNumberAlreadyExistResponse.data
          .numberExists
      } else {
        this.isUniquePhoneNumber = true
      }
    },
  },
  async mounted() {
    if (!this.isModeAdd) {
      await this.populateFormData()
    }
  },
  methods: {
    async populateFormData() {
      const phoneNumberResponse = await phoneNumbers.getPhoneNumber(
        this.$route.params.id
      )
      this.phone = phoneNumberResponse.data.number
      this.populateBrandDetails(this.phone.brandId)
      this.populateLeadSourceDetails(this.phone.leadSourceTypeId)

      if (this.isModeEdit || this.isModeView) {
        this.originalPhoneNumber = this.getRawNumber(this.phone.phoneNumber)
      }
    },
    getRawNumber(number) {
      return ('' + number).replace(/\D/g, '')
    },
    async searchBrandsList(value) {
      const filterObject = {
        column: {
          _t_id: 'brand_search_id',
          prop: 'brandName',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        if (value.length === 0) {
          return
        }
      } else {
        return
      }
      const brandsFilter = filter()
      const parentFilter = brandsFilter.createParent('and')
      brandsFilter.add(parentFilter, filterObject)
      const params = {
        filters: brandsFilter.asQueryParams(),
        pageSize: 5,
      }
      const matchedBrands = await brands
        .brandsTableview(params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })
      this.suggestedBrands = [].concat(matchedBrands?.data?.resultList || [])
    },
    selectBrandFromList(brand) {
      if (brand) {
        this.brand = brand
        this.phone.brandId = brand.brandId
      }
    },
    async populateBrandDetails(brandId) {
      const brandResponse = await brands.getBrand(brandId)
      const brand = brandResponse.data.brand
      this.suggestedBrands.push(brand)
      this.selectBrandFromList(brand)
    },
    resetBrand() {
      this.brand = {
        brandName: null,
        brandId: null,
      }
      this.phone.brandId = null
    },
    async searchLeadSourceList(value) {
      if (typeof value !== 'string' || value.length === 0) {
        return
      }

      const filterObject = {
        column: {
          _t_id: 'lead_source_search_id',
          prop: 'displayName',
          filterType: 'contains',
          filterAsIs: true,
        },
        value,
      }
      const leadSourceFilter = filter()
      const parentFilter = leadSourceFilter.createParent('and')
      leadSourceFilter.add(parentFilter, filterObject)
      if (this.leadSourceDebounce) {
        clearTimeout(this.leadSourceDebounce)
      }
      this.leadSourceDebounce = setTimeout(async () => {
        const params = {
          filters: leadSourceFilter.asQueryParams(),
          pageSize: 5,
        }
        const leadSourcesResponse = await leadSources.getLeadSources(params)
        const suggestedLeadSourceResultList = leadSourcesResponse.data.resultList.filter(
          (suggestedLeadSource) => suggestedLeadSource.internalName
        )
        let suggestedLeadSources = []
        suggestedLeadSourceResultList.forEach((leadSource) => {
          suggestedLeadSources.push({
            description: leadSource.description,
            id: leadSource.leadSourceId,
            key: leadSource.internalName,
            label: leadSource.displayName,
          })
        })
        this.suggestedLeadSources = suggestedLeadSources
      }, 500)
    },
    selectLeadSourceFromList(leadSource) {
      if (!leadSource) {
        return
      }
      this.leadSource = leadSource
      this.phone.leadSourceTypeId = leadSource.leadSourceId
    },
    resetLeadSource() {
      this.leadSource = {
        active: null,
        companyId: null,
        description: null,
        id: null,
        key: null,
        label: '',
      }
      this.phone.leadSourceTypeId = null
    },
    async populateLeadSourceDetails(leadSourceId) {
      const leadSourceResponse = await leadSources.getLeadSource(leadSourceId)
      const leadSource = leadSourceResponse.data
      this.suggestedLeadSources.push(leadSource)
      this.selectLeadSourceFromList(leadSource)
    },
    enableEdit() {
      this.$router.push({
        name: 'phone-numbers.edit',
        params: { id: this.phone.phoneNumberId },
      })
    },
    backToTable() {
      this.$router.push({ name: 'phone-numbers' })
    },
    cancelAction() {
      if (this.isModeEdit) {
        this.disableAndRevert()
        return
      }
      this.backToTable()
    },
    async disableAndRevert() {
      await this.populateFormData()
      this.$router.push({
        name: 'phone-numbers.view',
        params: { id: this.phone.phoneNumberId },
      })
    },
    getFriendlyNumber(rawNumber) {
      var match = rawNumber.substring(1).match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    },
    async savePhoneNumber() {
      const isValid = this.$refs['phone-number-form'].validate()
      if (isValid) {
        let payload = deepClone(this.phone)
        const rawNumber = this.getRawNumber(this.phone.phoneNumber)
        payload.phoneNumber = `+${rawNumber}`
        payload.leadSourceTypeId = this.leadSource?.id
        payload.friendlyNumber = this.getFriendlyNumber(rawNumber)
        if (this.isModeEdit) {
          try {
            await phoneNumbers.updatePhoneNumber(payload)
            this.$router.push({
              name: 'phone-numbers.view',
              params: { id: this.phone.phoneNumberId },
            })
            this.$store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Phone number updated',
            })
          } catch (err) {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Error updating phone number',
            })
          }
        } else if (this.isModeAdd) {
          try {
            const phoneNumberResponse = await phoneNumbers.createPhoneNumber(
              payload
            )
            this.phone = phoneNumberResponse.data.number
            this.$router.push({
              name: 'phone-numbers.view',
              params: { id: this.phone.phoneNumberId },
            })
            this.$store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Phone number added',
            })
          } catch (err) {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: 'Error adding phone number',
            })
          }
        }
      }
    },
  },
}
</script>
